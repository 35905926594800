@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accueil {
  margin-top: 55px !important;
  background-image: url('./assets/logo-site/sbs.gif');
  
  
}
.bg-footer{
  background-image: url('./assets/logo-site/sbs.gif');
}

.u-partners-card-item {
  flex: none;
  display: flex;
}

.u-partners-card-wrap {
  flex: 0 100%;
  justify-content: flex-start;
  /* padding-top: 2rem;
  padding-bottom: 1.5rem; */
  display: flex;
  overflow: hidden;
}

.u-partners-card {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
  overflow: hidden;
}

.u-partners-card-inline-wrap {
  animation: scroll 100s linear infinite;
  flex: none;
  display: inline-block;
}

@keyframes scroll {
  0% {
    transform: translateX(-0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.img {
  width: 100px;
}


.text-animate {
  /* font-size: 24px; */
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
  letter-spacing: 3px;
  animation: zoomInOut 15s infinite;
}
@keyframes zoomInOut {
  0%, 20% {
      transform: scale(0.8);
  }
  10% {
      transform: scale(1);
  }
}

.prdt-style{
  background-color: white !important;
}
.content-crl-img{
  background-color: rgba(25, 25, 25, 0.8);
  border-radius: 10%;
  /* padding-top: 10 !important; */
  
}

.content-crl-img2{
  
  background-color: rgba(37, 81, 240, 0.5);
  border-radius: 10%;
}
.img-objet{
  /* object-fit: cover; */
  width: 70%;
  padding: 10px;
  background-color: rgba(25, 25, 25, 0.8);
  /* border-radius: 10% !important; */
}
.img-objet1{
  width: 100%;
  padding: 10px;
}
.bg-carousel{
  
  background-color: rgba(25, 25, 25, 0.8);
  border-radius: 10%;
  /* height: 100% !important; */
  border: 1px rgba(25, 25, 25, 0.8) solid ;
  
}
.holilink-stl{
  background-color: rgba(25, 25, 25, 0.9);
  border-radius: 10%;
  padding: 10px;
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}


.faq-stl{
  background-color: rgba(34, 34, 34, 0.9);
  border-radius: 10%;
  padding: 10px;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
.contact-stl{
  background-color: rgba(20, 20, 20, 0.9);
  /* border-radius: 10%; */
  padding: 10px;
}
.tl-bg{
  background-color: rgba(79, 170, 80, 0.8);
  border-top-width: 2px;
  border-top-color: white;
}
.content-crl-img1{
  background-color: rgba(25, 25, 25, 0.8);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.fade-down-right {
  animation: fadeDownRightAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeDownRightAnimation {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}